<template>
  <div class="page home-page">
    <h1 v-show="false">Интернет магазин Финикмаркет</h1>
    <div class="container-padding">
      <div class="page__inner">
        <PromocodeComponent hide-promo big />
        <SetsListComponent :sets="sets_first_block" position="sides" />
        <!--        <PromocodeComponent hide-benefits big />-->
        <BannersSliderComponent :items="banners" />
        <ProductsSliderComponent title="Что покупают" :items="what_buy" />
        <SetsListComponent :sets="sets_second_block" position="left" />
        <CategoriesComponent
          v-for="(item, i) in categories"
          :key="i"
          :title="item.title"
          :items="item.childs"
          :style="{ order: i }"
        />
        <ProductsSliderComponent title="Хиты продаж" :items="bestseller" class="home-page__bestseller" />
      </div>
    </div>
  </div>
</template>

<script>
import SetsListComponent from "components/SetsListComponent.vue";
import PromocodeComponent from "components/PromocodeComponent.vue";
import BannersSliderComponent from "components/slider/BannersSliderComponent.vue";
import ProductsSliderComponent from "components/slider/ProductsSliderComponent.vue";
import CategoriesComponent from "./components/CategoriesComponent.vue";
import HOME_PAGE from "@/graphql/pages/HomePage.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.state.home_page.sets_first_block = data.sets_first_block?.data || [];
        store.state.home_page.sets_second_block = data.sets_second_block?.data || [];
        store.state.home_page.banners = data.banners || [];
        store.state.home_page.what_buy = data.what_buy || [];
        store.state.home_page.bestseller = data.bestseller || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
  },
  data() {
    return {
      staticSet: {
        title: "Каталог всех наборов",
        more: true,
      },
    };
  },
  computed: {
    sets_first_block() {
      let blocks = JSON.parse(JSON.stringify(this.$store.state.home_page.sets_first_block)) || [];
      blocks.push(this.staticSet);
      return blocks;
    },
    sets_second_block() {
      return this.$store.state.home_page.sets_second_block;
    },
    banners() {
      return this.$store.state.home_page.banners;
    },
    what_buy() {
      return this.$store.state.home_page.what_buy;
    },
    bestseller() {
      return this.$store.state.home_page.bestseller;
    },
    categories() {
      return this.$store.state.global.categories.filter((i) => i.childs && i.childs.length);
    },
  },
  metaInfo: {
    title: "FinikMarket",
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
  components: {
    CategoriesComponent,
    ProductsSliderComponent,
    BannersSliderComponent,
    PromocodeComponent,
    SetsListComponent,
  },
};
</script>

<style lang="stylus">
.home-page {
  .list {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap var(--gap)
  }

  &__bestseller {
    order 1
  }
}
</style>
