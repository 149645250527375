var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categories"},[(_vm.title)?_c('h2',{staticClass:"categories-title"},[_c('router-link',{attrs:{"to":{
        name: 'catalog',
        params: { id: _vm.items && _vm.items.length ? _vm.items[0].id : null },
      }}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),_c('IconComponent',{attrs:{"name":"arrow-small-right-1"}})],1)],1):_vm._e(),_c('div',{staticClass:"categories-list",class:{
      'categories-list--sides': _vm.position === 'sides',
      'categories-list--left': _vm.position === 'left',
      'categories-list--col-3': _vm.items.length === 3,
      'categories-list--col-6': _vm.items.length >= 4,
    }},_vm._l((_vm.items),function(item,i){return _c('router-link',{key:i,staticClass:"categories-item no-hover hover-image",class:{ 'categories-item--big': _vm.bigItems.includes(i + 1), 'categories-item--white': item.white },attrs:{"to":{ name: 'catalog', params: { id: item.id } }}},[_c('ImgComponent',{staticClass:"categories-item__img",attrs:{"img":item.head_img,"width":"800","height":"400"}}),_c('span',{staticClass:"categories-item__title"},[_vm._v(_vm._s(item.title))])],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }