<template>
  <div class="categories">
    <h2 v-if="title" class="categories-title">
      <router-link
        :to="{
          name: 'catalog',
          params: { id: items && items.length ? items[0].id : null },
        }"
      >
        {{ title }}
        <IconComponent name="arrow-small-right-1" />
      </router-link>
    </h2>
    <div
      class="categories-list"
      :class="{
        'categories-list--sides': position === 'sides',
        'categories-list--left': position === 'left',
        'categories-list--col-3': items.length === 3,
        'categories-list--col-6': items.length >= 4,
      }"
    >
      <router-link
        :to="{ name: 'catalog', params: { id: item.id } }"
        v-for="(item, i) in items"
        :key="i"
        class="categories-item no-hover hover-image"
        :class="{ 'categories-item--big': bigItems.includes(i + 1), 'categories-item--white': item.white }"
      >
        <ImgComponent class="categories-item__img" :img="item.head_img" width="800" height="400" />
        <span class="categories-item__title">{{ item.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CategoriesComponent",
  components: { IconComponent },
  props: {
    title: String,
    items: Array,
    position: {
      type: String,
      validator(value) {
        return value === "left" || value === "sides" || value === "right";
      },
      default: "left",
    },
  },
  computed: {
    // позиции больших элементов
    bigItems() {
      if (this.items.length === 5) {
        return [3];
      }
      if (this.items.length === 4) {
        if (this.position === "sides") {
          return [1, 4];
        }
        return [1, 2];
      }
      return [];
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.categories {
  --size: 200px
  +below(440px) {
    --size 176px
  }

  svg{
    max-width 20px
    max-height 20px
  }

  &-title {
    a {
      display flex
      gap: 15px
      align-items center
    }
  }

  &-list {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap var(--gap)
    overflow auto
    +below(380px) {
      grid-template-columns 1fr !important
    }

    &--col-3 {
      grid-template-columns repeat(3, 1fr)
      +below(640px) {
        grid-template-columns 1fr 1fr

        > *:first-child {
          +above(381px) {
            grid-column span 2
          }
        }
      }
    }

    &--col-6 {
      grid-template-columns repeat(6, 1fr)
      grid-auto-flow row dense

      &.categories-list--left {
        +below(1150px) {
          grid-template-columns 1fr 1fr

          .categories-item--big {
            grid-column auto
          }
        }
      }

      &.categories-list--sides {
        +below(1150px) {
          grid-template-columns repeat(3, 1fr)
        }
        +below(580px) {
          grid-template-columns repeat(2, 1fr)
        }
      }
    }
  }

  &-item {
    position relative
    height var(--size)
    overflow: hidden;

    &--big {
      +above(381px) {
        grid-column span 2
      }
    }

    &--white {
      color var(--white)
    }

    &__img {
      border-radius var(--big-radius)
      width 100%
      height 100%
      object-fit cover
      object-position center
    }

    &__title {
      absolute left right top bottom
      padding 20px
      font-weight: 700;
    }
  }
}
</style>
